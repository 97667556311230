import React from "react";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { MDXProvider } from "@mdx-js/react";
import pageComponents from "../page-components";

class BlogPostTemplate extends React.Component {
  render() {
    const { menu, footerMenu, subMenu, parent } = this.props.pageContext;
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    let link = null;
    if (post.frontmatter.link && post.frontmatter.linktitle) {
      link = {
        title: post.frontmatter.linktitle,
        url: post.frontmatter.link
      };
    }

    return (
      <MDXProvider components={pageComponents}>
        <Layout
          location={this.props.location}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          parent={parent}
          menu={menu}
          subMenu={subMenu}
          footerMenu={footerMenu}
          link={link}
          image={post.frontmatter.image && post.frontmatter.image.relativePath}
          imageAlt={post.frontmatter.imagealt}
        >
          <SEO title={post.frontmatter.title} description={post.excerpt} />
          <MDXRenderer>{post.code.body}</MDXRenderer>
        </Layout>
      </MDXProvider>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        subtitle
        date(formatString: "DD. MMMM YYYY", locale: "de")
        linktitle
        link
        image {
          relativePath
        }
        imagealt
      }
      code {
        body
      }
    }
  }
`;
